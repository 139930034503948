<template>
    <woot-modal :show.sync="show" :on-close="onClose">
      <div class="column content-box">
        <woot-modal-header
          :header-title="$t('GENERAL_SETTINGS.AUTH2F.MODAL_CONECT.TITLE')"
          :header-content="$t('GENERAL_SETTINGS.AUTH2F.MODAL_CONECT.SUB_TITLE')"
        />


        <div class="text-center  columns padding-2">
          <qrcode-vue :value="linkOtp" :size="250" level="L" />
          <div class="code-copy">
            <woot-code :script="otpCode" />
          </div>

          <div class="card-form">
            <p>
              {{ $t('GENERAL_SETTINGS.AUTH2F.MODAL_CONECT.6_DIGIT_CODE') }}
            </p>
            <input type="number" v-model="code"/>
          </div>

          <div class="footer">
            <woot-button :isDisabled="isDisabled" @click="verifyCode2F">
              {{ $t('GENERAL_SETTINGS.AUTH2F.MODAL_CONECT.CONECT_BTN') }}
            </woot-button>
          </div>

        </div>
      </div>
    </woot-modal>
  </template>
  
  <script>
  import QrcodeVue from 'qrcode.vue';
  import { mapGetters } from 'vuex';
  export default {
    name: 'ModalAuth2F',
    components: { QrcodeVue },
    data(){
      return {
        code: ''
      }
    },
    props: {
      close: {
        type: Function,
        default: () => {},
      },
      show: {
        type: Boolean,
        default: false
      },
      otpCode: {
        type: String,
        default: ''
      },
      accountName: {
        type: String,
        default: ''
      }
    },

    computed: {
      ...mapGetters({
      accountId: 'getCurrentAccountId',
    }),
      isDisabled(){
        return String(this.code).length < 6
      },
      linkOtp(){
        const productName = encodeURIComponent('Omnidesk');
        const issuer = encodeURIComponent(this.accountName);
        return `otpauth://totp/${productName}?secret=${this.otpCode}&issuer=${issuer}`;
      }
    },
    methods: {
      showAlert(message) {
        bus.$emit('newToastMessage', message);
      },

      onClose() {
        this.$emit('close');
      },

      

      verifyCode2F(){
        const payload = {
          accountId: this.accountId,
          code: String(this.code)
        }
        this.$store
        .dispatch('verifyCode2F', payload)
        .then((res) => {
          if(res){
            this.showAlert(this.$t('GENERAL_SETTINGS.AUTH2F.MODAL_CONECT.REQUEST_SUCCESS'))
            this.$emit('enableAuth2Factor');
            return;
          }
          this.showAlert(this.$t('GENERAL_SETTINGS.AUTH2F.MODAL_CONECT.REQUEST_ERROR'))
        })
      }
    }
  };
  </script>
  
  <style scoped>
  .code-copy{
    margin-top: 1rem !important;
  } 
  .full-access {
    color: #01c6d8 !important;
  }
  .card-form {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
    align-items: center;
  }

  input {
    margin: 0;
    width: 20%;
  }

  .footer {
    display: flex;
    justify-content: end;
  }
  </style>
  