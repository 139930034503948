<template>
  <main class="medium-12 column login">
    <section class="text-center medium-12 login__hero align-self-top">
      <div class="logo-interativa">
        <img
          :src="require('../../../../../public/pingo2.svg')"
          :alt="globalConfig.installationName"
        />
        <img
          :src="require('../../../../../public/logoEscura.svg')"
          :alt="globalConfig.installationName"
          class="hero__logo"
        />
      </div>
      <h2 class="hero__title">
        Entrar no Omnidesk
      </h2>
    </section>
    <section class="row align-center">
      <div v-if="!email" class="small-12 medium-4 column">
        <div class="login-box column align-self-top">
          <GoogleOAuthButton
            v-if="showGoogleOAuth()"
            button-size="large"
            class="oauth-reverse"
          />
          <form class="column log-in-form" @submit.prevent="login()">
            <label :class="{ error: $v.credentials.email.$error }">
             Email
              <input
                v-model.trim="credentials.email"
                type="text"
                data-testid="email_input"
                placeholder="Email: alguem@exemplo.com"
                @input="$v.credentials.email.$touch"
              />
            </label>
            <label :class="{ error: $v.credentials.password.$error }">
              Senha
              <input
                v-model.trim="credentials.password"
                type="password"
                data-testid="password_input"
                placeholder="Senha"
                @input="$v.credentials.password.$touch"
              />
            </label>
            <woot-submit-button
              :disabled="
                $v.credentials.email.$invalid ||
                  $v.credentials.password.$invalid ||
                  loginApi.showLoading
              "
              button-text="Entrar"
              :loading="loginApi.showLoading"
              button-class="large expanded"
            />
          </form>
        </div>
        <div class="text-center column sigin__footer">
          <p v-if="!globalConfig.disableUserProfileUpdate">
            <router-link to="auth/reset/password" class="btn-primary">
              Esqueceu-se da sua senha?
            </router-link>
          </p>
          <p v-if="showSignupLink()">
            <router-link to="auth/signup">
              {{ $t('LOGIN.CREATE_NEW_ACCOUNT') }}
            </router-link>
          </p>
        </div>
      </div>
      <woot-spinner v-else size="" />
    </section>
    <woot-modal-verify-code-auth-2-f 
      v-if="openModalAuth2F"
      :show="openModalAuth2F"
      :accountIdSession="accountID"
      @close="toggleModalVerifyCode"
      @success-auth-2F="confirmingAuth2F"
      :access_token="access_token"
      :email="credentials.email"
    />
  </main>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';
import WootSubmitButton from 'components/buttons/FormSubmitButton';
import { mapGetters } from 'vuex';
import { parseBoolean } from '@chatwoot/utils';
import GoogleOAuthButton from '../../components/ui/Auth/GoogleOAuthButton.vue';
import { getLoginRedirectURL } from '../../helper/URLHelper';
import { setAuthCredentials } from '../../store/utils/api';

const ERROR_MESSAGES = {
  'no-account-found': 'LOGIN.OAUTH.NO_ACCOUNT_FOUND',
  'business-account-only': 'LOGIN.OAUTH.BUSINESS_ACCOUNTS_ONLY',
};

export default {
  components: {
    WootSubmitButton,
    GoogleOAuthButton,
  },
  mixins: [globalConfigMixin],
  props: {
    ssoAuthToken: { type: String, default: '' },
    ssoAccountId: { type: String, default: '' },
    ssoConversationId: { type: String, default: '' },
    config: { type: String, default: '' },
    email: { type: String, default: '' },
    authError: { type: String, default: '' },
  },
  data() {
    return {
      // We need to initialize the component with any
      // properties that will be used in it
      session: {},
      accountID: null,
      credentials: {
        email: '',
        password: '',
      },
      loginApi: {
        message: '',
        showLoading: false,
      },
      error: '',
      openModalAuth2F: false,
      user: {}
    };
  },
  validations: {
    credentials: {
      password: {
        required,
      },
      email: {
        required,
        email,
      },
    },
  },
  computed: {
    ...mapGetters({
      globalConfig: 'globalConfig/get',
    }),
  },
  created() {
    if (this.ssoAuthToken) {
      this.login();
    }
    if (this.authError) {
      const message = ERROR_MESSAGES[this.authError] ?? 'LOGIN.API.UNAUTH';
      this.showAlert(this.$t(message));
      // wait for idle state
      window.requestIdleCallback(() => {
        // Remove the error query param from the url
        const { query } = this.$route;
        this.$router.replace({ query: { ...query, error: undefined } });
      });
    }
  },
  methods: {

    toggleModalVerifyCode(){
      this.openModalAuth2F = !this.openModalAuth2F;
    },
    showAlert(message) {
      // Reset loading, current selected agent
      this.loginApi.showLoading = false;
      this.loginApi.message = message;
      bus.$emit('newToastMessage', this.loginApi.message);
    },
    showSignupLink() {
      return parseBoolean(window.chatwootConfig.signupEnabled);
    },
    showGoogleOAuth() {
      return Boolean(window.chatwootConfig.googleOAuthClientId);
    },

    confirmingAuth2F(){
      this.setAuthAndRedirect(this.session)
    },

    setAuthAndRedirect(res){
      setAuthCredentials(res)
      localStorage.setItem("session", "ok");
      this.showAlert("Logado com sucesso!");
      window.location = getLoginRedirectURL({
        ssoAccountId: this.ssoAccountId,
        ssoConversationId: this.ssoConversationId,
        user: res.data,
      });
    },

    login() {
      this.loginApi.showLoading = true;
      const credentials = {
        email: this.email
          ? decodeURIComponent(this.email)
          : this.credentials.email,
        password: this.credentials.password,
        sso_auth_token: this.ssoAuthToken,
        ssoAccountId: this.ssoAccountId,
        ssoConversationId: this.ssoConversationId,
      };
      this.$store
        .dispatch('login', credentials)
        .then((res) => {
          const data = res?.data?.data;
          if (
            data.role === 'SuperAdmin' || 
            !data?.accounts[0]?.enable_2_factor_authentication || 
            !data.enable_2_factor_authentication
          ) {
            this.setAuthAndRedirect(res);
          }else{
            this.session = res;
            this.accountID = data?.accounts[0]?.id
            this.access_token = data?.access_token;
            this.toggleModalVerifyCode();
          }
        })
        .catch(response => {
          // Reset URL Params if the authentication is invalid
          if (this.email) {
            window.location = '/app/login';
          }

          if (response && response.status === 401) {
              this.showAlert("Credenciais inválida, Por favor, tente novamente.");
            return;
          }
          this.showAlert("Erro ao logar, Por favor, tente novamente.");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.oauth-reverse {
  display: flex;
  flex-direction: column-reverse;
}

.logo-interativa {
  display: flex;
  justify-content: center;
  height: 50px;
  gap: 10px;
}
</style>
