<template>
    <woot-modal :show.sync="show" :on-close="onClose">
      <div class="column content-box">
        <woot-modal-header
          :header-title="$t('GENERAL_SETTINGS.AUTH2F.MODAL_CHECK_CODE.TITLE')"
          :header-content="$t('GENERAL_SETTINGS.AUTH2F.MODAL_CHECK_CODE.SUB_TITLE')"
        />


        <div class="text-center  columns padding-2">
          <div class="card-form">
            <p>
              {{ $t('GENERAL_SETTINGS.AUTH2F.MODAL_CHECK_CODE.6_DIGIT_CODE') }}
            </p>
            <input type="number" v-model="code"/>
          </div>

          <div class="footer">
            <woot-button :isDisabled="isDisabled" @click="verifyCode2F">
              {{ $t('GENERAL_SETTINGS.AUTH2F.MODAL_CHECK_CODE.CHECK_BTN') }} 
            </woot-button>
          </div>
          
          <div class="card-form los-pass">
            <p @click="handleShowCodeMail" v-if="!isCodeEmail">
              {{ $t('GENERAL_SETTINGS.AUTH2F.MODAL_CHECK_CODE.CHECK_EMAIL') }} 
            </p>
            <p @click="handleShowCodeMail" v-else>
              {{ $t('GENERAL_SETTINGS.AUTH2F.MODAL_CHECK_CODE.CHECK_GOOGLE_AUTH') }} 
            </p>
          </div>
        </div>
      </div>
    </woot-modal>
  </template>
  
  <script>
  import { mapGetters } from 'vuex';
  export default {
    name: 'ModalVerifyAuth2F',
    data(){
      return {
        code: '',
        isCodeEmail: false
      }
    },
    props: {
      close: {
        type: Function,
        default: () => {},
      },
      show: {
        type: Boolean,
        default: false
      },
      accountIdSession: {
        type: Number,
        default: 0
      },
      access_token: {
        type: String,
        default: ''
      },
      email: {
        type: String,
        default: ''
      },
    },

    computed: {
      ...mapGetters({
      accountId: 'getCurrentAccountId',
    }),
      isDisabled(){
        return String(this.code).length < 6
      }
    },
    methods: {

      sendCodeEmail(){
        const payload = {
          accountId: this.accountId || this.accountIdSession,
          code: String(this.code),
          accessToken: this.access_token,
          email: this.email
        }

        this.$store
        .dispatch('sendCodeEmail', payload)
        .then((res) => {
          const mesage = `${this.$t('GENERAL_SETTINGS.AUTH2F.MODAL_CHECK_CODE.NEW_CODE')} ${this.email}` 
          this.showAlert(mesage);
        })
        
      },
      handleShowCodeMail(){
        this.isCodeEmail = !this.isCodeEmail;
        if(this.isCodeEmail){
          this.sendCodeEmail();
        }
      },
      showAlert(message) {
        bus.$emit('newToastMessage', message);
      },

      onClose() {
        this.$emit('close');
      },

      
      verifyCode2F(){
        const payload = {
          accountId: this.accountId || this.accountIdSession,
          code: String(this.code),
          accessToken: this.access_token,
        }
        if(this.isCodeEmail){
          payload.email = this.email;
        }
        this.$store
        .dispatch('verifyCode2F', payload)
        .then((res) => {
          if(res){
            this.$emit('success-auth-2F');
            return;
          }
          this.showAlert(this.$t('GENERAL_SETTINGS.AUTH2F.MODAL_CHECK_CODE.REQUEST_ERROR'))
        })
        .catch(() => {
          this.showAlert(this.$t('GENERAL_SETTINGS.AUTH2F.MODAL_CHECK_CODE.REQUEST_ERROR'))
        })
      }
    }
  };
  </script>
  
  <style scoped>
  .full-access {
    color: #01c6d8 !important;
  }
  .card-form {
    display: flex;
    gap: 1rem;
    padding: 1rem 1.5rem !important;
    margin-top: 1rem;
    align-items: center;
  }

  input {
    margin: 0;
  }

  input[type='email']{
    width: 50%;
  }

  input[type='number'] {
    width: 20%;
  }

  .footer {
    display: flex;
    justify-content: end;
  }

  .los-pass p {
    font-size: 1.2rem;
    font-weight: 500;
    color: var(--g-20) !important;
    cursor: pointer;
  }
  </style>
  